import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useGateway } from "../../../context/GatewayContext";
import NewApi from "../../../services/new-api";
import CheckoutApi from "../../../services/checkout-api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const newApi = new NewApi();
const checkoutApi = new CheckoutApi();

export default function ProcessingCheckout() {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(true);

  const { yuno } = useGateway();

  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  
  const checkoutSession = urlParams.get("checkoutSession");

  const token = urlParams.get("token");

  const handleStatus = async () => {
    if (checkoutSession && token) {
      const status = await yuno.yunoPaymentResult(checkoutSession);

      if (status === "DECLINED") {
        setIsProcessing(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("Checkout.errors.3dsVerification"),
          didClose: () => {
            history.push(`/checkout/${token}`);
          },
        });
      }

      if (status === "PENDING") { 
        const { transaction_id } = await newApi
          .get(`orders/${token}?onlyOrder=true`)
          .then(({ data }) => data);

        await checkoutApi.post("/capture", {
          transaction_id: transaction_id,
          seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
        }).then(() => {
          history.push("/status/checkout/concluded");
        }).catch(() => {
          setIsProcessing(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("Checkout.errors.captureTransaction"),
          });
        })
        
      }

      if (status === 'SUCCEEDED') {
        history.push("/status/checkout/concluded");
      }
    } else {
      const { status } = JSON.parse(sessionStorage.getItem("tickets"));

      setIsProcessing(false)
      if (["em_análise", "pré-autorizada"].includes(status)) {
        history.push("/status/checkout/pending");
      } else if (status === "falhada") {
        history.push("/status/checkout/error");
      } else {
        history.push("/status/checkout/concluded");
      }
    }
  };

  useEffect(() => {
    handleStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSession, token]);

  return (
    <Fragment>
      {isProcessing && (
        <Loader title="aguarde..." text={t("Checkout.processing")} />
      )}
    </Fragment>
  );
}
