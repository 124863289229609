import React, { useEffect } from "react";
import { removeCheckoutData } from "../../../Helpers/checkout";
import "./index.css";

export default function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
    removeCheckoutData();
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        <h1>Purchase Policy</h1>
        <p>
          PAll sales are FINAL, and refunds are only allowed in limited
          circumstances, as explained in this section and the Canceled,
          Postponed, Rescheduled and Moved Events section bellow. Before
          purchasing tickets, carefully review your event and seat selection.
          Policies set forth by Event Organizers generally prohibit us from
          issuing exchanges or refunds after a ticket has been purchased, or for
          lost, stolen, damaged, or destroyed tickets. Tickets therefore cannot
          be replaced if they are lost, stolen, or damaged.
        </p>

        <h4>
          <strong>Refunds</strong>
        </h4>

        <p>
          If your event is eligible for refunds and funds have been received
          from the Event Organizer, we will issue a refund of the ticket price
          you paid. If a refund is issued, it will be processed to the original
          method of payment used at time of purchase. We cannot issue a refund
          to a different credit or debit card (this includes refund requests
          made through the self-service option). If your credit card or debit
          card number has changed, but is for the same account (e.g., a new card
          has been issued for the same account), the refund will be processed to
          that account.
        </p>
        <p>
          Please note that seating maps are representative of a venue’s layout;
          however, they are subject to change at any time, and refunds are not
          allowed if the seating map is updated, if additional seats / rows are
          added, or if seats change as a result of a venue change after
          purchase.
        </p>
        <p>
          We may occasionally offer tickets at a discount after the original
          on-sale date and will not refund the difference between the original
          price and the sale price. In addition, there are no refunds, returns,
          or exchanges for digital downloads or festival packages. If there is
          an account, or accounts, that have outstanding balances owed to
          TktWave that reserves the right to hold any and all refunds for said
          accounts until such time as no outstanding balances remain.
        </p>

        <h4>
          <strong>Credits</strong>
        </h4>

        <p>
          The Event Organizer may provide the option to request a credit for
          canceled, postponed, rescheduled, or moved events; if so, we will send
          you a notification, and you can submit a request for credit in your
          account. Credits are provided by the Event Organizer, and not by
          TktWave.
        </p>

        <p>
          Credits are non-transferable, may not be sold, are not redeemable for
          cash, and may not be combined with other promotions. Credits may not
          be applied to previously placed orders and may not be redeemed to
          purchase tickets for the same event as the tickets in your original
          purchase for which you accepted the credit.
        </p>

        <p>
          Please note that, if the Event Organizer offers you a choice of either
          a refund or a credit, you cannot get a partial refund; whatever
          selection you make (i.e., refund or credit) will apply to your entire
          order and cannot be split between refund and credit. Any and all
          requests for a refund or credit are final and cannot be changed once
          initiated.
        </p>

        <h4>
          <strong>Exchanges</strong>
        </h4>

        <p>
          For many events, certain tickets may be exchanged for tickets from us
          of equal or higher price. A per ticket exchange fee may apply.
          Exchanges are not available for: (a) tickets purchased through resale,
          (b) VIP and other premium tickets, or (c) tickets purchased with
          additional related items and/or bundled products, or (d) tickets for
          events that are less than 24 hours away.
        </p>

        <h4>
          <strong>Merchandise</strong>
        </h4>

        <p>
          All merchandise purchases are final, and in no event will there be
          refunds, exchanges, or credits for merchandise purchases.
        </p>

        <h4>
          <strong>Chargebacks and Other Refund Prohibitions</strong>
        </h4>

        <p>
          You agree that you will not attempt to evade, avoid, or circumvent any
          refund prohibitions in any manner with regard to tickets you
          purchased. Without limiting the generality of the foregoing, you will
          not contact us to seek a refund or exchange from us when we are
          prohibited from providing one by the Event Organizer, and you will not
          dispute or otherwise seek a “chargeback” from the company whose credit
          card or other method of payment you used to purchase tickets from the
          Site. Should you do so, your tickets are subject to immediate
          cancelation, and we may, in our sole discretion, refuse to honor
          pending and future ticket purchases made from all credit card accounts
          or online accounts on which such chargebacks have been made, and may
          prohibit future purchases from all persons in whose name the credit
          card accounts exist, and from any person who accesses any associated
          online account or credit card or who otherwise breaches this provision
          from using the Site.
        </p>

        <h4>
          <strong>
            Special note about resale tickets purchased from Tktwave.com
          </strong>
        </h4>

        <p>
          Except for canceled events (as explained in Canceled, Postponed,
          Rescheduled and Moved Events, bellow), there are no refunds,
          exchanges, or cancelations once a request is submitted to us. This
          policy is in place because, when you place your ticket request on
          Tktwave.com, we attempt to secure and acquire those tickets for you
          from a ticket seller and are not able to recover our costs. To be
          clear: if we have provided you with an Order Confirmation email for
          your Tktwave purchase, even though you may have not received your
          requested tickets, we have already committed to acquiring and securing
          those tickets for you. Please order tickets only after you are certain
          you want them.
        </p>

        <h4>
          <strong>Cancellations, Time Changes and Event Dates</strong>
        </h4>

        <p>
          If the event is canceled: no action is required to obtain a refund; we
          will issue a refund to the original method of payment used at time of
          purchase, once funds are received from the Event Organizer. In some
          cases, the Event Organizer may also give you the option to choose
          either a credit or a refund; if so, we will send you a notification
          explaining your options, and how to submit a request for a credit.
        </p>

        <p>
          If the event is postponed, rescheduled, or moved: your ticket(s)
          (including any additional add-ons or upgrades, such as parking) are
          still valid, and no further action is required. However, the Event
          Organizer may approve refunds, or the option to choose between a
          refund or a credit, for the event; any refund and/or credit policies
          are determined on an event-by-event basis by the Event Organizer and
          may be subject to limitations set by the Event Organizer. If the Event
          Organizer approves refunds and/or credits, we will send you a
          notification explaining your options, and you may submit a request for
          a refund or credit. Alternatively, you may simply keep your ticket(s)
          to the postponed, rescheduled, or moved event.
        </p>

        <p>
          If your event is canceled, postponed, rescheduled, or moved, we will
          attempt to contact you to update you on the status of the event, and
          inform you of any refund, credit, or exchange procedures. For exact
          instructions on any particular canceled, postponed, rescheduled, or
          moved event, please check the event information online and in your
          account (which will include the most current information on the status
          of the event), or contact us.
        </p>

        <p>
          We will not be liable for travel or any other expenses that you or
          anyone else incurs in connection with a canceled, postponed,
          rescheduled, or moved event.
        </p>

        <h4>
          <strong>Account, Order, and Billing Information Verification</strong>
        </h4>

        <p>
          All information on accounts and orders must be valid and are subject
          to verification. Orders are subject to credit card approval, and are
          processed only after the billing address associated with your credit
          card and other billing information have been verified. Orders that are
          placed, or attempted to be placed, using an account with any
          information that is found to be false, misleading, incorrect, or
          incomplete, or that cannot be verified as belonging to the account
          holder—such as name, address, email address, phone number, IP address,
          or other account or billing information—are subject to cancelation, at
          any time. Furthermore, if your order is canceled for any of the
          foregoing reasons, we may sell your tickets to another customer
          without further notice.
        </p>

        <h4>
          <strong>
            License, Ejection and Cancelation, and No Redemption Value
          </strong>
        </h4>

        <p>
          You agree to comply with all of the Event Organizer’s applicable
          rules, policies, terms, and conditions (“Event Organizer Rules”). The
          Event Organizer reserves the right, without refund of any amount paid,
          to refuse admission to, or eject, any person whose conduct management
          deems disorderly, who uses vulgar or abusive language, or who fails to
          comply with Event Organizer Rules. Breach of terms or rules will
          terminate your license to attend the event without refund. A ticket is
          a revocable license and admission may be refused. A ticket is not
          redeemable for cash.
        </p>

        <h4>
          <strong>Unlawful Resale of Tickets and Promotions</strong>
        </h4>

        <p>
          Unlawful resale (or attempted unlawful resale) of tickets, including
          but not limited to counterfeit or copy of tickets, is grounds for
          seizure and cancelation without compensation. Certain maximum resale
          premiums and restrictions may apply in some states, and we cannot be
          responsible for providing legal advice regarding resale or pricing of
          tickets. You are responsible for complying with all applicable ticket
          resale laws. In addition, we reserve the right to restrict or deny
          ticket purchasing privileges to anyone that we determine to be, or to
          have been, in violation of our policies. Because we do not guarantee
          the authenticity of tickets purchased from any non-authorized third
          party reseller (such as brokers or individuals), we recommend that you
          purchase tickets directly through us, through authorized partners, or
          from the venue box office to ensure ticket authenticity. Tickets may
          not be used for advertising, promotions, contests, or sweepstakes,
          unless formal written authorization is given by us.
        </p>

        <h4>
          <strong>Who You Are Buying From</strong>
        </h4>

        <p>
          We act as the agent to those who provide events, such as artists,
          venues, teams, fan clubs, promoters, and leagues (the “Event
          Organizer”). We generally sell tickets on behalf of the Event
          Organizer, though, in some rare instances, we may own a small number
          of tickets as part of our services contract with the Event Organizer.
          When you purchase a ticket for an event that is located in the United
          States, Ticketmaster LLC will be handling the transaction and
          collecting payment for the Event Organizer. When you purchase a ticket
          for an event that is located in Canada, Ticketmaster Canada Ltd. will
          be handling the transaction and collecting payment for the Event
          Organizer.
        </p>

        <p>
          If you purchase a resale ticket through our Site, you will be
          purchasing that ticket from either (a) a reseller who is not an Event
          Organizer, such as other fans, season ticket holders, or professional
          resellers, or (b) in limited circumstances, the Event Organizer.
          Tickets that are obtained from unauthorized sources, and not directly
          through our Site, may be lost, stolen, or counterfeit, and, if so, are
          void.
        </p>
      </div>
    </div>
  );
}
