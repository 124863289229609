import React from "react";

export default function CookiesTerms() {
  return (
    <div className="container mt-4 mb-4">
      <div className="row terms">
        <h1>COOKIES POLICY</h1>
        <p>
          Welcome to the TktWave platform! The following "Cookies Policy" is
          based on our principles and values. We take your privacy very
          seriously and therefore protect your personal data.
          <br />
          <br />
          Our website uses "cookies" to improve performance, ensure security,
          enhance your browsing experience so that we can offer a more
          personalized navigation.
          <br />
          <br />
          The information obtained through cookies does not allow the
          identification of personal data of the user and, therefore, is treated
          by TktWave as non-personal information, however, if there is any
          change in the applicable legislation and these data become considered
          personal information, TktWave will observe the relevant legislation.
          This policy provides the main information related to the use of
          cookies on our website.
        </p>
        <h4>DEFINITIONS</h4>
        <p>
          a) Local storage: industry standard technology that allows a website
          or app to store and retrieve data from a user's computer, mobile
          device, or other device.
          <br />
          b) Device identifier: codes that enable the identification of a mobile
          device, whether persistently or temporarily, such as Advertising ID or
          Operating System ID.
          <br />
          c) Pixel tag: small blocks of code on a web page that allow them to
          perform actions such as reading and storing cookies and transmitting
          information to TktWave. The resulting connection may include
          information such as a device's IP address, the time a person viewed
          the pixel, an identifier associated with the browser or device, and
          the type of browser in use.
          <br />
          d) TktWave platform: designates the technological platform available
          to event organizers and venues, facilitating the sale of tickets,
          event registration, event contribution billing, and participant
          management, accessible at the electronic address
          https://www.tktwave.com.
          <br />
          e) Personal data treatment: any operation carried out with personal
          data, such as those referring to collection, production, reception,
          classification, use, access, reproduction, transmission, distribution,
          processing, filing, storage, elimination, evaluation or control of the
          information, modification, communication, transfer, dissemination, or
          extraction.
        </p>
        <h4>Considerations about cookies</h4>
        <p>
          1.1. Cookies are files that contain an identifier (a sequence of
          letters and numbers) sent by a server to a specific browser that
          stores it. The identifier is then sent back to the server whenever the
          browser attempts to access a page from the server.
          <br />
          1.2. Cookies can be "persistent" or "session-based." A persistent
          cookie will be stored by a browser and remain valid until its
          expiration date, unless deleted by the user before this date. A
          session cookie, on the other hand, will expire at the end of a user's
          browsing session, when the browser is closed.
          <br />
          1.3. TktWave treats information obtained through cookies and other
          technologies as non-personal information. However, to the extent that
          IP address or similar identifiers are considered personal information
          under applicable law, TktWave will treat such identifiers as personal
          information.
        </p>
        <h4>2. Use of cookies</h4>
        <p>
          2.1. TktWave uses cookies and other technologies for the following
          purposes:
          <br />
          a) Authentication: indicates that the user is logged in, in order to
          provide appropriate features and understand how the user uses and
          accesses the TktWave platform;
          <br />
          b) Advertising and measurement: helps to analyze browsing behavior, to
          deliver and measure ads, make them more relevant, and analyze the
          content and use of products and services;
          <br />
          c) Security and integrity: helps to maintain the security of services,
          providing support or activating security features and helping to
          detect activities that violate our legal policies;
          <br />
          d) Features and services: helps to provide products and services and
          personalized content;
          <br />
          e) Performance: helps to provide the best possible experience, such as
          helping to route traffic between servers and realizing how quickly the
          service is loaded for different people. Occasionally, information may
          be stored in the browser or device so that the features in use load.
          <br />
          2.2. The user can manage their cookie preferences and other similar
          technologies from their browser or device settings, by rejecting or
          deleting certain cookies and technologies. However, this may
          compromise the provision of services or prevent the functioning of
          certain features that they integrate. Below, we list some URLs where
          the user can manage such preferences:
          <br />- https://support.google.com/chrome/answer/95647 (Chrome);
          <br />-
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          (Firefox);
          <br />- https://www.opera.com/help/tutorials/security/cookies/
          (Opera);
          <br />-
          https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          (Internet Explorer);
          <br />- https://support.apple.com/pt-br/guide/safari/sfri11471/mac
          (Safari);
          <br />-
          https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          (Edge).
        </p>
        <h4>3. Third-party cookies</h4>
        <p>
          3.1. The service providers used by TktWave may use cookies and other
          technologies of their own to identify the browser and device used, in
          order to offer targeted advertising of TktWave when the user accesses
          third-party websites or apps. These third parties may also provide
          TktWave with information about the performance of marketing campaigns
          developed.
          <br />
          3.2. The user can opt-out of the above-mentioned third-party
          advertising services through the tools:
          <br />
          a) "YourAdChoices": http://optout.aboutads.info/?c=2&lang=ENb
          <br />
          b)"Evidon":
          http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true
        </p>
        <h4>4. Tracking Technologies</h4>
        <p>
          4.1. TktWave may use other tracking technologies, such as web beacons
          (sometimes called "tracking pixels," "pixel tags," or "clear gifs").
          These technologies also help Organizers learn more about interest in
          certain events. In many cases, these technologies rely on cookies to
          function properly.
          <br />
          4.2. TktWave may use a click-through URL linked to platform content.
          By clicking on one of these URLs, users may have personal data
          processed, based on TktWave's legitimate interest, to understand
          interest in certain topics and evaluate the effectiveness of
          communications with users.
        </p>
        <h4>5. General Provisions</h4>
        <p>
          5.1. By browsing the TktWave platform and using its features, users
          accept all the provisions in this Policy and other legal policies that
          are in force on the date of access. Therefore, it is recommended that
          users keep themselves updated.
          <br />
          5.2. This Cookie Policy is subject to constant improvement and
          refinement. Therefore, TktWave reserves the right to modify it at any
          time, according to the purpose of the TktWave platform, as well as for
          compliance and legal conformity of dispositions of laws or equivalent
          legal norms, and it is the responsibility of users to check it
          whenever accessing the TktWave platform.
          <br />
          5.3. Any tolerance for any violation of the terms and conditions
          contained in this domain will be considered mere liberality and will
          not be interpreted as novation, invoked precedent, waiver of rights,
          tacit amendment of contractual terms, acquired right or contractual
          amendment.
          <br />
          5.4. If any provision of this Policy is deemed inapplicable or without
          effect, the rest of the rules continue to be in force, without the
          need for a judicial measure that declares such assertion. The terms
          described herein will be interpreted according to Brazilian
          legislation.
          <br />
          5.5. Communication between TktWave and the user must be carried out
          through the service channels indicated and made available on the
          TktWave platform, especially through the email address
          info@tktwave.com
          <br />
          5.6. The jurisdiction of the Michigan State, is elected to resolve any
          doubts arising from this Agreement, excluding any other jurisdiction,
          however privileged it may be.
        </p>
      </div>
    </div>
  );
}
