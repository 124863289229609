import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export function validateCpf(number) {
  const BLACKLIST = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
    "12345678909",
  ];

  const stripped = (number || "").replace(/[^\d]/g, "");

  // CPF must be defined
  if (!stripped) {
    return false;
  }

  // CPF must have 11 chars
  if (stripped.length !== 11) {
    return false;
  }

  // CPF can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 9);
  numbers += cpfVerifierDigit(numbers);
  numbers += cpfVerifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
}

export function validateCnpj(number) {
  const BLACKLIST = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ];

  const stripped = (number || "").replace(/[^\d]/g, "");

  // CNPJ must be defined
  if (!stripped) {
    return false;
  }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) {
    return false;
  }

  // CNPJ can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 12);
  numbers += cnpjVerifierDigit(numbers);
  numbers += cnpjVerifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
}

export function validatePhone(phone) {
  const brazilianPhoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
  return brazilianPhoneRegex.test(phone);
}

export function validateBirthdate(date) {
  const dateRegex =
    /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/;

  if (dateRegex.test(date)) {
    return dayjs(date, "MM/DD/YYYY").isValid();
  }

  return false;
}

function cpfVerifierDigit(digits) {
  const numbers = digits.split("").map((number) => {
    return parseInt(number, 10);
  });

  const modulus = numbers.length + 1;
  const multiplied = numbers.map((number, index) => number * (modulus - index));
  const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;

  return mod < 2 ? 0 : 11 - mod;
}

function cnpjVerifierDigit(digits) {
  let index = 2;
  const reverse = digits.split("").reduce((buffer, number) => {
    return [parseInt(number, 10)].concat(buffer);
  }, []);

  const sum = reverse.reduce((buffer, number) => {
    buffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
}
