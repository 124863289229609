import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import MaskHelper from "../../Helpers/mask";
import "./styles.css";

const seatsRegion = process.env.REACT_APP_SEATS_REGION;
const seatsWorkspace = process.env.REACT_APP_SEATSWORKSPACE;
const currencyMask = MaskHelper.createCurrencyMask("en-US", "USD");

const EventSeatMap = () => {
  const { event_id } = useParams();

  return (
    <Fragment>
      <div className="content-wrapper" key="wrapper">
        <Container fluid className="p-0">
          <div
            id="seats-render"
            className="seats-render w-100 w-lg-100 w-md-100"
          >
            <SeatsioSeatingChart
              showMinimap
              showSeatLabels
              event={event_id}
              workspaceKey={seatsWorkspace}
              language={seatsRegion === "sa" ? "pt" : "en"}
              region={seatsRegion}
              divId="seats-render"
              mode="static"
              priceFormatter={(price) => currencyMask(price)}
            />
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default EventSeatMap;
