import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import AuthenticationFlow from "../../components/Auth";

export default function Auth() {
  const { isLogged } = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (isLogged) {
      window.location.href = urlParams.get("next") || "/";
    }
  }, [isLogged, urlParams]);

  return (
    <div style={{ backgroundColor: "#00658d", height: "calc(100vh - 305px)" }}>
      <AuthenticationFlow show onClose={() => window.location.reload()} />
    </div>
  );
}
