import React, { useEffect } from "react";

import "./index.css";

export default function Termos() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        <h1>TERMS OF USE</h1>

        <p>
          • The ticket is valid only for the event, date, time, place and seat
          to which it was issued. Please check the data carefully at the time of
          purchase. You must reach the event at least 30 minutes in advance.
          <br />• Keep your ticket in a safe place, away from the heat, avoiding
          folds and contact with other materials that could damage it.
          <br />• By determination of the organization of the event, the doors
          should be closed at the time informed on the ticket. There will be no
          refund for ticket holders arriving after doors are closed.
          <br />• There will be no ticket exchange, no refund of money in case
          of delay or purchase on the eve of the show.
          <br />• In case of regret, we inform you that it is not possible to
          request cancellation or exchange of tickets.
          <br />• If there is any exception, any change in the event date or any
          other event outside the normality in which it is necessary to make a
          refund, it may take up to 45 days to be processed.
          <br />• There will be no ticket exchange, no refund of money in case
          of delay or purchase on the eve of the show.
          <br />• It is not possible to request cancellation and refund after
          the date of the event.
          <br />• TktWave is not responsible for tickets purchased outside our
          points of sale.
          <br />• Always check the page of the event on our website to update
          any changes that may occur in the event, and always keep your email
          address updated on our website to receive the latest updates on the
          event, such as location changes, date and cancellations. TktWave is
          exempt from any responsibility in case the customer`s message box is
          full or refusing to receive new e-mails, being this one of personal or
          professional use.
          <br />• The ticket has security items and will be subjected to
          verification at the event`s concierge. The bar code/QR code contained
          in the ticket is unique and does not repeat, guaranteeing only
          one-time access to the event.
          <br />• The production of the event reserves the right to ask the
          public for an official photo identification to access the event.
          <br />• This event can be recorded, filmed or photographed. By
          participating in the event, the holder of this ticket agrees and
          authorizes the free use of his image for an indeterminate period.
          <br />• TktWave has nothing to do with the organization of the event.
          Issues such as values, promotions, changes of date, restitution of
          values in cases provided by law and any other demand related to the
          event are the sole responsibility of its producers and organizers.
        </p>
      </div>
    </div>
  );
}
