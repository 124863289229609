import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en-US/translation.json";
import translationBR from "./locales/pt-BR/translation.json";
import translationES from "./locales/es-ES/translation.json";

const resources = {
  "en-US": {
    translation: translationEN,
  },
  "pt-BR": {
    translation: translationBR,
  },
  "es-ES": {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en-US",
    detection: {
      order: ["localStorage", "cookie", "navigator"],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
