/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import logo_mobile from "../../assets/svg/logo_small.png";
import logo_zig from "../../assets/svg/logo_white.png";
import { useAuth } from "../../hooks/useAuth";
import { useSearch } from "../../hooks/useSearch";
import AuthenticationFlow from "../Auth/index.js";
import { ChangeLanguageButton } from "../LanguageButton/index.js";
import SearchInput from "../SearchInput";
import { UserOptionsButton } from "../UserOptionsButton/index.js";
import "./index.css";

const Header = () => {
  const location = useLocation();
  const { typedText, selectedState, updateSearch } = useSearch();
  const { isLogged, logout } = useAuth();
  const { t } = useTranslation();
  const [searched, setSearch] = useState(typedText);
  const [showAuthFlow, setShowAuthFlow] = useState(false);
  const [showAuthSignUp, setShowAuthSignUp] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  const isOnCheckoutPage =
    location.pathname === "/checkout" || location.pathname === "/checkout/";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  };

  const updateState = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const sendToSearchResults = () => {
    updateSearch("search", searched);
    const state = selectedState || "";
    if (typedText) {
      const newURL = `/eventos?s=${searched}${state ? `&st=${state}` : ""}`;
      window.history.pushState({ path: newURL }, "", newURL);
    } else {
      window.location.href = `/eventos?s=${searched}${
        state ? `&st=${state}` : ""
      }`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const value = searched.trim();
      if (value !== "" || searched.length === 0) {
        sendToSearchResults(value);
      }
    }
  };

  const handleCloseAuthFlow = () => {
    setShowAuthFlow(false);
    setShowAuthSignUp(false);
  };

  useEffect(() => {
    if (window.location.pathname === "/cadastro") {
      setShowAuthSignUp(true);
      setShowAuthFlow(true);
    }
  }, []);

  return (
    <Fragment>
      <Navbar collapseOnSelect expand="lg" variant="dark" id="header">
        {isOnCheckoutPage && !isMobile && <div className="spacer" />}
        <Navbar.Brand>
          <a href="/">
            <img
              src={isMobile ? logo_mobile : logo_zig}
              alt="company-logo"
              className={isMobile ? "" : "header-logo"}
            />
          </a>
        </Navbar.Brand>
        <Nav
          className={`items-container ${
            isOnCheckoutPage && !isMobile && "full-width"
          }`}
          style={{
            display: "flex",
            gap: "1.25rem",
            justifyContent: "flex-end",
            alignContent: "center",
            flexDirection: "row",
          }}
        >
          {!/\/checkout\/.*/.test(location.pathname) && (
            <>
              <SearchInput
                value={searched || ""}
                onChange={updateState}
                onKeyDown={handleKeyDown}
              />
              {/* <LocationButton /> */}
            </>
          )}
        </Nav>

        {!isOnCheckoutPage && (
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            id="toggle-navbar-menu"
          />
        )}

        {!isOnCheckoutPage ? (
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="header-list"
            style={{
              maxWidth: isMobile ? "auto" : "35%",
              minWidth: "15%",
            }}
          >
            {/* {!isMobile && <CreateEventButton />} */}
            {!isMobile && <ChangeLanguageButton />}
            <Nav className="header-list justify-content-center">
              {!isLogged ? (
                <>
                  <button
                    onClick={() => setShowAuthFlow(true)}
                    className="modalBtn loginBtn"
                  >
                    <i className="far fa-user-circle" /> {t("Header.enter")}
                  </button>
                  {isMobile && <ChangeLanguageButton />}
                </>
              ) : (
                <Fragment>
                  <div className="d-flex align-items-center">
                    {!isMobile && (
                      <UserOptionsButton handleLogout={handleLogout} />
                    )}
                  </div>

                  <ul id="dropdown-list">
                    <a href="/meus-pedidos" className="drop-link">
                      {t("MyOrders.myOrders")}
                    </a>
                    <a href="/meus-ingressos" className="drop-link">
                      {t("Header.myTickets")}
                    </a>
                    <a href="/meus-eventos" className="drop-link">
                      {t("MyEvents.myEvents")}
                    </a>
                    <a href="/perfil" className="drop-link">
                      {t("Header.profileData")}
                    </a>
                    <span className="drop-link" onClick={handleLogout}>
                      {t("Header.logout")}
                    </span>
                  </ul>
                </Fragment>
              )}
            </Nav>
          </Navbar.Collapse>
        ) : null}
      </Navbar>
      <AuthenticationFlow
        show={showAuthFlow}
        showSignUp={showAuthSignUp}
        nextToUrl={window.location.href}
        onClose={handleCloseAuthFlow}
      />
    </Fragment>
  );
};

export default Header;
