import dayjs from "dayjs";
import ptBR from "dayjs/locale/pt-br";
import enUS from "dayjs/locale/en";
import esES from "dayjs/locale/es";

export default class DateHelper {
  static setLocale() {
    const i18nextLang = localStorage.getItem("i18nextLng");
    const allLocales = {
      "en-US": enUS,
      "es-ES": esES,
      "pt-BR": ptBR,
    };
    const selectedLocale = allLocales[i18nextLang];
    dayjs.locale(selectedLocale);
  }

  static shortDateTime(date) {
    return dayjs(date).format("MM/DD/YYYY HH:mm");
  }

  static format(date, format) {
    if (!date) {
      return "";
    }
    const cleanDate = date.substring(0, 19);
    const neutralDate = dayjs(cleanDate).format("YYYY-MM-DDTHH:mm:ss");
    return dayjs(neutralDate).format(format);
  }
}
