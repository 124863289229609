/* eslint-disable no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import * as yup from "yup";
import sendToLogger from "../../../Helpers/errorLogger";
import MaskHelper from "../../../Helpers/mask";
import { genderOptions } from "../../../services/constants";
import ErrorHandler from "../../../services/error-handler";
import MoengageService from "../../../services/moengage";
import NewApi from "../../../services/new-api";
import "./index.css";

export default function ProfileForm({ profileValues }) {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("ProfileForm.emailRequired"))
      .email(t("ProfileForm.invalidEmail")),
    firstName: yup
      .string()
      .required(t("CommonExpressions.nameRequired"))
      .matches(/^[^\d]+$/, t("CommonExpressions.nameInvalidCharacters"))
      .min(3, t("CommonExpressions.nameInvalidLength"))
      .trim(),
    lastName: yup
      .string()
      .required(t("CommonExpressions.lastnameRequired"))
      .matches(/^[^\d]+$/, t("CommonExpressions.lastnameInvalidCharacters"))
      .min(3, t("CommonExpressions.nameInvalidLength"))
      .trim(),
    document: yup.string().required(t("CommonExpressions.documentRequired")),
    phone: yup.string().trim(),
    birthdate: yup
      .string()
      .test(
        "valid-date",
        t("ProfileForm.invalidBirthdate"),
        (value) =>
          dayjs(value, "MM/DD/YYYY", true).isValid() &&
          dayjs(value, "MM/DD/YYYY").isBefore(dayjs(), "year")
      ),
    gender: yup.string().required(t("CommonExpressions.genderRequired")),
    facebook: yup.string().trim(),
    xTwitter: yup.string().trim(),
    instagram: yup.string().trim(),
    zipcode: yup.string().trim(),
    street: yup.string().trim(),
    neighborhood: yup.string().trim(),
    complement: yup.string().trim(),
    state: yup.string().trim(),
    city: yup.string().trim(),
    number: yup.string().trim(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: profileValues,
  });

  const onSubmit = (data) => {
    setSubmitting(true);

    const formData = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      document: MaskHelper.alphanumericMask(data.document),
      telephone: data.phone ? MaskHelper.alphanumericMask(data.phone) : null,
      instagram: data.instagram || null,
      facebook: data.facebook || null,
      x_twitter: data.xTwitter || null,
      birthdate: data.birthdate
        ? dayjs(data.birthdate, "MM/DD/YYYY").format("YYYY-MM-DD")
        : "",
      gender: data.gender,
      address: {
        zip_code: data.zipcode || null,
        street: data.street || null,
        neighborhood: data.neighborhood || null,
        complement: data.complement || null,
        state: data.state || null,
        city: data.city || null,
        number: data.number || null,
      },
    };

    new NewApi()
      .put("users/update", formData)
      .then(async ({ data }) => {
        localStorage.setItem("user", JSON.stringify(data));
        sessionStorage.setItem("user_sess", JSON.stringify(data));

        await MoengageService.trackUser({
          type: "customer",
          customer_id: data.id,
          attributes: {
            ...data,
            platforms: [{ platform: "web", active: true }],
          },
        });

        swal.fire({
          icon: "success",
          title: t("CommonExpressions.success"),
          text: t("ProfileForm.dataUpdated"),
        });
      })
      .catch((error) => {
        sendToLogger(error);

        swal.fire({
          icon: "error",
          title: t("CommonExpressions.ops"),
          html: ErrorHandler.handleFormErrors(error.response.data, t),
        });
      })
      .finally(() => setSubmitting(false));
  };

  const handleSearchZipcode = (zipcode) => {
    if (zipcode && zipcode.length === 9) {
      axios
        .get(`https://viacep.com.br/ws/${MaskHelper.numberMask(zipcode)}/json/`)
        .then((res) => {
          setValue("street", res.data.logradouro);
          setValue("neighborhood", res.data.bairro);
          setValue("city", res.data.localidade);
          setValue("state", res.data.uf);
          setValue("number", "");
          setValue("complement", "");
        })
        .catch(() => {});
    }
  };

  return (
    <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12} md={6}>
          <h4 className="mb-4">{t("ProfileForm.personalData")}</h4>
          <Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>{t("CommonExpressions.name")}</Form.Label>
                <Form.Control type="text" {...register("firstName")} />
                {errors.firstName && (
                  <Form.Text className="text-danger">
                    {errors.firstName.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>{t("CommonExpressions.lastname")}</Form.Label>
                <Form.Control type="text" {...register("lastName")} />
                {errors.lastName && (
                  <Form.Text className="text-danger">
                    {errors.lastName.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>{t("ProfileForm.personalData")}</Form.Label>
                <Form.Control type="email" {...register("email")} />
                {errors.email && (
                  <Form.Text className="text-danger">
                    {errors.email.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="document">
                <Form.Label>
                  {t("CommonExpressions.foreignDocument")}
                </Form.Label>
                <Form.Control type="text" {...register("document")} />
                {errors.document && (
                  <Form.Text className="text-danger">
                    {errors.document.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="phone">
                <Form.Label>{t("Checkout.phone")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("phone")}
                  onChange={(e) =>
                    setValue("phone", MaskHelper.phoneMask(e.target.value))
                  }
                />
                {errors.phone && (
                  <Form.Text className="text-danger">
                    {errors.phone.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="birthdate">
                <Form.Label>{t("UserPaymentCard.birthdate")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("birthdate")}
                  onChange={(e) =>
                    setValue("birthdate", MaskHelper.dateMask(e.target.value))
                  }
                />
                {errors.birthdate && (
                  <Form.Text className="text-danger">
                    {errors.birthdate.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="gender">
                <Form.Label>{t("CommonExpressions.gender")}</Form.Label>
                <Form.Control
                  as="select"
                  {...register("gender")}
                  onChange={(e) => setValue("gender", e.target.value)}
                >
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {t(`CommonExpressions.${option.label}`)}
                    </option>
                  ))}
                </Form.Control>
                {errors.gender && (
                  <Form.Text className="text-danger">
                    {errors.gender.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="mt-3 mb-4">{t("ProfileForm.socials")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="instagram">
                <Form.Label>Instagram</Form.Label>
                <Form.Control type="text" {...register("instagram")} />
                {errors.instagram && (
                  <Form.Text className="text-danger">
                    {errors.instagram.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="xTwitter">
                <Form.Label>X {t("ProfileForm.formerTwitter")}</Form.Label>
                <Form.Control type="text" {...register("xTwitter")} />
                {errors.xTwitter && (
                  <Form.Text className="text-danger">
                    {errors.xTwitter.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="facebook">
                <Form.Label>Facebook</Form.Label>
                <Form.Control type="text" {...register("facebook")} />
                {errors.facebook && (
                  <Form.Text className="text-danger">
                    {errors.facebook.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <h4 className="mb-4">{t("Checkout.address")}</h4>
          <Row>
            <Col>
              <Form.Group controlId="zipcode">
                <Form.Label>{t("Checkout.zipcode")}</Form.Label>
                <Form.Control type="text" {...register("zipcode")} />
                {errors.zipcode && (
                  <Form.Text className="text-danger">
                    {errors.zipcode.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="street">
                <Form.Label>{t("Checkout.address")}</Form.Label>
                <Form.Control type="text" {...register("street")} />
                {errors.street && (
                  <Form.Text className="text-danger">
                    {errors.street.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="number">
                <Form.Label>{t("Checkout.number")}</Form.Label>
                <Form.Control type="text" {...register("number")} />
                {errors.number && (
                  <Form.Text className="text-danger">
                    {errors.number.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="neighborhood">
                <Form.Label>{t("Checkout.neighborhood")}</Form.Label>
                <Form.Control type="text" {...register("neighborhood")} />
                {errors.neighborhood && (
                  <Form.Text className="text-danger">
                    {errors.neighborhood.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="complement">
                <Form.Label>{t("ProfileForm.complement")}</Form.Label>
                <Form.Control type="text" {...register("complement")} />
                {errors.complement && (
                  <Form.Text className="text-danger">
                    {errors.complement.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="city">
                <Form.Label>{t("Checkout.city")}</Form.Label>
                <Form.Control type="text" {...register("city")} />
                {errors.city && (
                  <Form.Text className="text-danger">
                    {errors.city.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="state">
                <Form.Label>{t("Checkout.state")}</Form.Label>
                <Form.Control type="text" {...register("state")} />
                {errors.state && (
                  <Form.Text className="text-danger">
                    {errors.state.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            type="submit"
            className="btn-logar mt-3"
            disabled={submitting}
          >
            {submitting
              ? t("CommonExpressions.updating")
              : t("ProfileForm.updateData")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
